import { Button } from '@hipagesgroup/toolbox';
import getQuote from '~/assets/getquote.png';
import { useGetQuotesPackageWrapperContext } from '../providers/GetQuotesPackageWrapper';

const Header = () => {
  const { openGetQuotesWithPrefilledCategory } = useGetQuotesPackageWrapperContext();
  return (
    <header className="py-ms bg-surface relative border-t-2 border-t-[#098cde]">
      <div className="max-w-[1008px] mx-auto px-md flex flex-row items-center justify-between gap-md">
        <a className="" href="/">
          <img className="h-[32px] md:h-[40px] w-auto block" src={getQuote} alt="GetQuote.com.au" />
        </a>

        <Button
          size="sm"
          onClick={() => {
            openGetQuotesWithPrefilledCategory();
          }}
        >
          Get Quotes
        </Button>
      </div>
    </header>
  );
};

export default Header;
